@import "@angular/material/prebuilt-themes/indigo-pink.css";

@font-face {
  font-family: graphic-regular-font;
  src: local("graphic-regular-font"), url(assets/fonts/graphic/Graphik-Regular.ttf) format("truetype");
}

@font-face {
  font-family: graphic-bold-font;
  src: local("graphic-bold-font"), url(assets/fonts/graphic/Graphik-Bold.ttf) format("truetype");
}

@font-face {
  font-family: graphic-semibold-font;
  src: local("graphic-semibold-font"), url(assets/fonts/graphic/Graphik-Semibold.ttf) format("truetype");
}

@font-face {
  font-family: graphic-medium-font;
  src: local("graphic-medium-font"), url(assets/fonts/graphic/Graphik-Medium.ttf) format("truetype");
}

@font-face {
  font-family: graphic-extralight-font;
  src: local("graphic-extralight-font"), url(assets/fonts/graphic/Graphik-Extralight.ttf) format("truetype");
}

/******** common css *********/

.toast-container {
  pointer-events: none !important; 
  position: fixed !important;
  z-index: 999999 !important;
}

a {
  color: #a001ff;
  text-decoration: none;
}

.cs-bold-text {
  font-weight: 600;
}

.bold-heading {
  font-weight: bold;
}

.border-bottom {
  border-bottom: .1rem solid #efefef !important;
}

.rounded-1rem {
  border-radius: .9rem !important;
}

body {
  font-family: graphic-regular-font !important;
  background: #ffffff;
  overflow-x: hidden;
}

header .navbar-toggler {
  margin-bottom: 5px;
}

header {
  background-repeat: no-repeat;
  background-size: cover;
}

header a.navbar-brand,
header a.navbar-brand:hover {
  margin-right: 25px;
  color: #ffffff;
  font-family: 'graphic-regular-font';
  font-weight: 600;
  display: table-cell;
}

header a.navbar-brand span {
  vertical-align: bottom;
  margin-left: 30px;
  font-family: "Graphik", "Arial";
  font-size: 19px;
  color: black;
  line-height: 38px;
  font-weight: 400;
  display: inline-block;
  margin-bottom: -3px;
}

header .navbar-brand img {
  width: 150px;
  border-right: 1px solid #D8D8D8;
  padding-right: 35px;
  padding-bottom: 6px;
}

header .navbar .nav-item .nav-link {
  color: #ffffff;
  padding: 36px 0 32px 0;
  font-weight: bold;
  margin: 0px 17px;
}

header .navbar .nav-item.active .nav-link,
header .navbar .nav-item:hover .nav-link {
  color: rgb(1, 142, 255);
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  letter-spacing: -0.35px;
  line-height: 20px;
}

.navbar-dark .navbar-toggler {
  color: #ffffff !important;
  border: 2px solid #a001ff !important;
  padding: 2px !important;
  margin: 5px !important;
}

.navbar-toggler:focus {
  box-shadow: 0 !important;
}

.dashboard-header {
  /* position: absolute !important; */
}

.dashboard-header-unset {
  position: fixed !important;
}

/*****************CS simulation dropdowns*********************/

a.cs-avatar {
  text-decoration: none;
  color: #9c9c9c;
  font-weight: bold;
}

.cs-form .dropdown .btn {
  text-align: left;
  min-width: 250px;
  border: 1px solid #dfe3e8;
  background-size: 20px;
  background-position: 95% 50%;
}

.cs-form .dropdown-menu>.inp-search {
  border-radius: 20px;
}

.cs-form .dropdown .btn:focus,
.cs-form .dropdown-menu>.inp-search:focus {
  box-shadow: none;
  border: .09rem solid #a100ff;
}

.cs-form .dropdown .btn small {
  font-size: 12px;
  color: #707f8c;
}

.dropdown-toggle::after {
  content: none;
}

.cs-form .dropdown-menu {
  min-width: 250px;
  margin-top: 5px !important;
  padding: .5rem 1rem .5rem 1rem;
  border: 0;
}

.cs-form .dropdown-menu>.list-group>.list-group-item {
  border: 0;
}

.cs-form .dropdown-menu>.list-group>.list-group-item.active {
  background-color: white;
  color: #000000;
  font-weight: bold;
}

.cs-form .dropdown-menu>.list-group {
  max-height: 100px;
  overflow: hidden;
  overflow-y: scroll;
  margin-top: 10px;
}

/******************Area of Interest*********************/

.bg-white {
  background-color: #ffffff !important;
}

.cs-placeholder {
  background-color: #f4f6f8;
  color: #6a6b6c;
}

.cs-placeholder span {
  font-weight: bold;
  font-size: 1rem;
  color: #707475;
}

.cs-options-placeholder {
  width: 125px;
}


/************** footer ******************/

footer .navbar {
  background-color: #ffffff;
  padding: 1rem;
}

footer .container-fluid {
  display: block !important;
}

footer .navbar-brand img {
  width: 100px;
  margin-top: -13px;
}

footer .navbar-text {
  font-size: 12px;
  color: #5E767D;
}

footer .navbar-nav {
  float: right;
}

footer .navbar .nav-item .nav-link {
  font-size: 12px;
  color: #5E767D;
  margin: 0 10px;
}

footer .navbar .nav-item.active .nav-link,
footer .navbar .nav-item:hover .nav-link {
  color: #94a0ad;
}


/************Area of interest carousel***************/

.cs-aoi-carousel>.cs-aoi-item>.card {
  background: #ffffff;
  border: 1px solid rgb(223, 227, 232);
  border-radius: 8px;
}

.cs-aoi-carousel>.cs-aoi-item.active>.card {
  background: rgb(250, 245, 253);
  border: 1.5px solid rgb(161, 0, 255);
}

.cs-aoi-carousel>.cs-aoi-item>.card>.card-body>.card-title {
  color: rgb(33, 43, 54);
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.32px;
  line-height: 18px;
}

.cs-aoi-carousel>.cs-aoi-item>.card>.card-body>.card-title>img {
  width: 60px;
  height: 60px;
  float: right;
}

.cs-aoi-carousel>.cs-aoi-item>.card>.card-body>.card-text {
  color: rgb(145, 158, 171);
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 18px;
}

.cs-aoi-carousel>.cs-aoi-item>.card>.card-body>.btn-explore {
  background: rgb(161, 0, 255);
  border-radius: 16px;
  color: rgb(255, 255, 255);
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.25px;
  line-height: 12px;
}


/**************** career options tabs ***********************/

.cs-co-tabs>.nav-pills>.nav-item>.nav-link {
  background-color: #ffffff;
  color: #0088ff;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.27px;
  line-height: 22px;
}

.cs-co-tabs>.nav-pills>.nav-item>.nav-link.active,
.cs-co-tabs>.nav-pills>.nav-item>.nav-link:hover {
  border-bottom: 5px solid #018eff;
}


/************career options carousel***************/

.cs-co-carousel>.cs-co-item>.card {
  background: rgb(244, 244, 244);
  border: 0;
  border-radius: 8px;
}

.cs-co-carousel>.cs-co-item.active>.card {
  background: rgb(252, 247, 255);
  border: 2px solid rgb(161, 0, 255);
  box-shadow: 0px 2px 48px 0px rgba(33, 43, 54, 0.25);
  border-radius: 8px;
}

.cs-co-carousel>.cs-co-item>.card>.card-body>.card-title {
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.31px;
  line-height: 20px;
}

.cs-co-carousel>.cs-co-item>.card>.card-body>.card-title>img {
  width: 60px;
  height: 60px;
  float: right;
}

.cs-co-carousel>.cs-co-item>.card>.card-body>.card-text {
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.31px;
  line-height: 20px;
}

.cs-co-carousel>.cs-co-item>.card>.card-body>.card-text>p>.cl-ico {
  width: 30px;
  height: 30px;
  margin-right: 5px;
}


/************ Role description ****************/

.cs-role-descr-header {
  background: rgb(252, 247, 255);
  border: .12rem solid rgb(161, 0, 255);
  border-radius: 0px;
  padding: 10px;
}

.btn-long-term-career {
  background: rgb(161, 0, 255);
  border-radius: 24px;
  padding: 8px 20px !important;
}

.cs-role-title {
  padding: 10px 0 0 0;
  font-weight: bold;
}

.cs-role-descr-container .cs-role-descr-controls {
  text-align: center;
}

.cs-role-descr-container .cs-role-descr-controls .control-link img {
  width: 20px;
  margin: 10px;
}

.role-descr h6 {
  color: rgb(0, 0, 0);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.35px;
  line-height: 20px;
}

.role-descr p {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 18px;
}

.role-descr .btn-readmore {
  color: #a50bff;
  text-decoration: none;
  font-weight: bold;
}

.cs-herefrom-role .card-title {
  color: rgb(0, 0, 0);
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.32px;
  line-height: 30px;
}

.skill-descr h6 {
  color: rgb(0, 0, 0);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.35px;
  line-height: 20px;
}

.skill-descr p {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 18px;
}

.skill-descr .list-group .list-group-item {
  border: none;
  border-bottom: 1px solid #dce1e6;
  padding: 1.5rem 0;
}

.skill-descr .list-group .list-group-item:last-child {
  border: none;
}

.skill-descr .list-group .list-group-item label {
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.25px;
  line-height: 20px;
  min-width: 140px;
}

.skill-descr .list-group .list-group-item a {
  color: rgb(161, 0, 255);
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 20px;
  padding: 0 18px;
  text-decoration: none;
  vertical-align: middle;
}

.skill-descr .list-group .list-group-item a img {
  max-width: 15px;
}

.learning-source .card {
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 32px 0px rgba(0, 0, 0, 0.1);
  border: 0;
  border-radius: 8px;
  margin-bottom: 10px;
}

.learning-source .card .card-body a.card-title {
  color: rgb(161, 0, 255);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.25px;
  line-height: 18px;
  text-decoration: none;
  text-align: left;
}

.learning-source .card .card-body a.card-title img {
  max-width: 25px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.learning-source .card .card-body p {
  padding-top: 10px;
  color: rgb(99, 115, 129);
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 1.5;
}

::-webkit-scrollbar {
  width: 8px;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #cfcfcf;
  border-radius: 10px;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #cfcfcf;
}

.cdk-overlay-container .cdk-overlay-pane .dropdownclass {
  position: absolute;
  top: 5vh;
  left: 0.3vw;
}

.cdk-overlay-container .cdk-overlay-pane .filterDropdown {
  position: absolute;
  top: 1vh;
  left: 0.3vw;
}

/****Common button and text box border css*****/
.btn:focus {
  box-shadow: none !important;
}

input:focus {
  outline: none !important;
  border-color: #212529 !important;
  box-shadow: none !important;
}

textarea:focus {
  outline: none !important;
  border-color: #212529 !important;
  box-shadow: none !important;
}

/****End*****/

@media (min-width:769px) {

  header .navbar .nav-item.active .nav-link,
  header .navbar .nav-item:hover .nav-link {
    color: rgb(1, 142, 255);
    border-bottom: 5px solid rgb(1, 142, 255);
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    letter-spacing: -0.35px;
    line-height: 20px;
  }

}

@media screen and (max-width:480px) {
  .toast-top-right {
    top: 50px !important;
    right: 53px !important;
  }
}

@media (min-width:481px) and (max-width:767.67px) {
  .toast-top-right {
    top: 12px !important;
    right: 220px !important;
  }
}

@media (max-width:900px) {
  header .navbar {
    padding: 0px;
  }
}

.btn-container>.btn,
.btn-container>.btn:hover,
.btn-container>.btn:active,
.btn-container>.btn:focus {
  background: rgb(161, 0, 255);
  padding: .375rem .75rem;
  border-radius: 24px;
  font-size: .8rem;
  border: none;
}

.btn-container>.btn:disabled {
  background: rgb(161, 0, 255);
  padding: 5px 17px;
  padding: .375rem .75rem;
  border-radius: 24px;
  font-size: .8rem;
  border: none;
  pointer-events: none;
  opacity: .65;
}

.btn-container>.btn-secondary,
.btn-container>.btn-secondary:hover,
.btn-container>.btn-secondary:active {
  background-color: #ffffff;
  color: rgb(161, 0, 255);
  border: 2px solid rgb(161, 0, 255);
}

.sce-h0 {
  font-family: 'graphic-semibold-font' !important;
  font-size: 1.75rem !important;
  font-weight: 500 !important;
}

.sce-h1 {
  font-size: 1rem !important;

}

.sce-h1,
.sce-h2 {
  font-family: 'graphic-regular-font' !important;
  font-weight: 700 !important;
}

.sce-h2 {
  font-size: .9rem !important;
}

.sce-t1 {
  font-family: 'graphic-regular-font' !important;
  font-weight: 500 !important;
  font-size: .8rem !important;
  letter-spacing: -.25px !important;
}

mat-spinner {
  width: 50px !important;
  height: 50px !important;
}